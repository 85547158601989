<template>
  <v-dialog v-model="showDialog" max-width="1350px">
    <v-card class="row m-0">
      <v-flex col-xs-12 col-sm-12 col-md-12 col-lg-6 pb-0 pb-lg-3 class="border-right">
        <v-card-title class="headline" primary-title>
          Campaign invitation
        </v-card-title>
        <v-card-subtitle class="greytxt--text">
          You can invite {{ user.business.allowed_active_influencers }} in total.
        </v-card-subtitle>
        <v-card-text class="pb-0">
          <campaign-autocomplete
            v-if="showCampaignSelect"
            v-model="campaign"
            :returnObject="true"
            :items="campaigns"
          ></campaign-autocomplete>
          <v-flex
            class="row m-0" 
            v-if="campaigns && !showCampaignSelect && campaign"
          >
            <div class="col-12 d-flex">
              <div class="mr-4">
                <v-avatar :size="32">
                  <img :src="campaign.image_url" />
                </v-avatar>
              </div>
              <div class="subtitle-1">
                <span class="mr-4">{{ campaign.name }} <img class="ml-2" width="20" :src="'/img/flags/' + campaign.country.flag" /></span>
                <span class="cursor-pointer" @click="editCampaign(true)">
                  <v-tooltip :disabled="$vuetify.breakpoint.xsOnly" top>
                    <template v-slot:activator="{ on }">
                      <v-icon size="12px" class="greytxt--text" v-on="on">
                        fal fa-pencil
                      </v-icon>
                    </template>
                    <span>Change campaign</span>
                  </v-tooltip>
                </span>
              </div>
            </div>
          </v-flex>
          <v-flex v-if="$vuetify.breakpoint.lgAndUp">
            <simple-rich-text-input
              v-model="inviteNote"
              :height="145"
              :minlength="50"
              :maxlength="550"
              :textStrength="invitationNoteProgressIndicator"
              :customVariables="customVariables"
              :emojiOnly="true"
              :placeholder="editorPlaceholder"
              :label="{
                text: 'Invite message',
                tooltip: {
                  icon: 'fal fa-info-circle',
                  text: 'Read more here',
                  link: 'https://intercom.help/Make-influence-com/da/articles/6248621-hvad-kan-jeg-skrive-i-chatbeskederne-til-influencers'
                }
              }"
              :returnOnlyText="true"
              @valueCount="inviteNoteCount = $event"
            ></simple-rich-text-input>
          </v-flex>
        </v-card-text>
        <v-card-actions v-if="$vuetify.breakpoint.lgAndUp">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">
            Close
          </v-btn>
          <v-btn
            color="success"
            :loading="loading"
            :disabled="isInviteNoteInvalid"
            @click="submit()"
          >
            Invite
          </v-btn>
        </v-card-actions>
      </v-flex>
      <v-flex col-xs-12 col-sm-12 col-md-12 col-lg-6 pt-0 pt-lg-3>
        <v-flex m-0 row align-center>
          <v-flex col-xs-12 col-sm-12 col-md-12 col-lg-6 py-0 py-lg-3>
            <influencer-autocomplete
              label="Search name"
              v-model="tempInfluencer"
              :returnObject="true"
              :disabled="!canAddMoreInfluencers"
              ref="influencerAutocomplete"
              :onlyName="true"
              :exclude="excludeInfluencers"
              :country-codes="countryCodes"
            ></influencer-autocomplete>
          </v-flex>
          <v-flex col-xs-12 col-sm-12 col-md-12 col-lg-6 d-flex pt-0 pt-lg-3 v-if="campaign">
            <v-btn text color="accent" class="border-round-xl custom-button">
              <span>{{ user.business.remaining_allowed_influencers }}</span>
              <span>/</span>
              <span class="mr-2">{{ user.business.allowed_active_influencers }}</span>
              <span>Available Invitations</span>
            </v-btn>
          </v-flex>
          <v-alert class="w-100 p-2" v-if="!campaign" type="warning" prepend>
            <template slot="prepend">
              <v-icon color="warning" small>
                fal fa-exclamation-triangle
              </v-icon>
            </template>
            <template>
              <div class="ml-2">
                Please select campaign
              </div>
            </template>
          </v-alert>
          <v-alert class="w-100 p-2" v-if="campaign && !canAddMoreInfluencers" type="warning" prepend>
            <template slot="prepend">
              <v-icon color="warning" small>
                fal fa-exclamation-triangle
              </v-icon>
            </template>
            <template>
              <div class="ml-2">
                You can't add any more influencers
              </div>
            </template>
          </v-alert>
          <v-flex class="influencers-table-wrapper" v-if="$vuetify.breakpoint.lgAndUp">
            <v-data-table
              :headers="influencerHeaders"
              :items="influencers"
              :items-per-page="999"
              hide-default-footer
            >
              <v-progress-linear
                slot="progress"
                color="primary"
                indeterminate
              ></v-progress-linear>
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, k) in items" :key="k">
                    <td>
                      <div class="d-flex align-center">
                        <span class="mr-2">
                          <user-avatar :user="item.user"></user-avatar>
                        </span>
                        <div class="d-flex">
                          {{ item.user.full_name }}
                        </div>
                        <div class="d-flex ml-3">
                          <v-img width="20" :src="'/img/flags/' + item.country.flag" class="rounded-sm"></v-img>
                        </div>
                        <!-- If already invited -->
                        <v-tooltip
                          :disabled="$vuetify.breakpoint.xsOnly"
                          top
                          v-if="
                            validationExistingInvites.includes(item.uuid)
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <div v-on="on" class="d-flex ml-3 cursor-pointer">
                              <v-icon color="warning" small>
                                fal fa-exclamation-triangle
                              </v-icon>
                            </div>
                          </template>
                          <span>
                            Already invited
                          </span>
                        </v-tooltip>
                        <v-tooltip
                           :disabled="$vuetify.breakpoint.xsOnly"
                           top
                           v-if="
                            hasInfluencerAlreadyApplied(item.uuid)
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <div v-on="on" class="d-flex ml-3 cursor-pointer">
                              <v-icon color="warning" small>
                                fal fa-exclamation-triangle
                              </v-icon>
                            </div>
                          </template>
                          <span>
                            Already applied
                          </span>
                        </v-tooltip>
                        <!-- if restricted -->
                        <v-tooltip
                          :disabled="$vuetify.breakpoint.xsOnly"
                          top
                          v-else-if="item.restricted"
                        >
                          <template v-slot:activator="{ on }">
                            <div v-on="on" class="d-flex ml-3 cursor-pointer">
                              <v-icon color="warning" small>
                                fal fa-exclamation-triangle
                              </v-icon>
                            </div>
                          </template>
                          <span>
                            Minor
                          </span>
                        </v-tooltip>
                      </div>
                    </td>
                    <td>
                      {{ item.channels[0].followers | friendly_numbers }}
                      -
                      {{ item.channels[0].channel.display_name }}
                    </td>
                    <td>
                      <div class='d-flex'>
                        <span>{{ item.comission }}</span>
                        <span class="ml-1 mr-2">
                          <small>{{ campaignPricing === 'cost_per_action_percent' ? '%' : 'DKK' }}</small>
                        </span>
                        <span v-if="item.max_commission_budget" class="mr-2">
                          | <small>{{ item.max_commission_budget }} DKK</small>
                        </span>
                        <v-menu
                          v-model="item.menu"
                          :close-on-content-click="false"
                          :nudge-width="20"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <span>
                              <v-icon
                                size="12px"
                                class="pb-1 greytxt--text cursor-pointer"
                                v-bind="attrs"
                                v-on="on"
                              >
                                fal fa-pencil
                              </v-icon>
                            </span>
                          </template>
                          <v-card>
                            <v-card-text>
                              <v-text-field
                                 @change.native="isCommissionValid(item)"
                                 label="Change commission"
                                 name="comission"
                                 type="number"
                                 v-model="item.comissionTemp"
                                 v-validate="{
                                   rules: { decimal: 2, required: true, min_value: 0 }
                                 }"
                                 :error-messages="errors.collect('comission')"
                                 :suffix="campaignPricing === 'cost_per_action_percent' ? '%' : 'DKK'"
                              ></v-text-field>
                              <v-text-field
                                 @change.native="isCommissionValid(item)"
                                 v-if="campaignPricing === 'cost_per_click'"
                                 label="Max budget"
                                 name="max_commission_budget"
                                 type="number"
                                 v-model="item.max_commission_budget_tmp"
                                 v-validate="{
                                   rules: { decimal: 2, required: true, min_value: 100 }
                                 }"
                                 data-vv-as="Max budget"
                                 hint="Type in value (excl. VAT and service fee)"
                                 :error-messages="errors.collect('max_commission_budget')"
                                 class="mt-3"
                                 suffix="DKK"
                              ></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn small color="priamry" text @click="closeItemMenu(item)">
                                Cancel
                              </v-btn>
                              <v-btn small color="success" @click="changeItemComission(item)" :disabled="!isCommissionValid(item)">
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                      </div>
                    </td>
                    <td>
                      <v-btn text color="accent" @click="removeInfluencer(item.uuid)">
                        Remove
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
            <v-flex v-if="influencers.length === 0" class="d-flex justify-center my-4">
              Start searching for influencers 🚀
            </v-flex>
          </v-flex>
          <!-- mobile table -->
          <v-flex class="influencers-table-wrapper bordered border-round-xs" v-if="$vuetify.breakpoint.mdAndDown">
            <v-data-table
              :headers="influencerHeaders"
              :items="influencers"
              :items-per-page="999"
              hide-default-footer
              hide-default-header
            >
              <v-progress-linear
                slot="progress"
                color="primary"
                indeterminate
              ></v-progress-linear>
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, k) in items" :key="k">
                    <td>
                      <div class="d-flex align-center">
                        <div class="d-flex">
                          {{ item.user.full_name }}
                        </div>
                        <div class="d-flex ml-3">
                          <v-img width="20" :src="'/img/flags/' + item.country.flag" class="rounded-sm"></v-img>
                        </div>
                        <div
                          class="d-flex ml-3 cursor-pointer"
                          v-if="
                            validationExistingInfluencers.includes(item.uuid) ||
                              item.restricted || influencerCountryValidate(item)
                          "
                        >
                          <v-icon color="warning" small>
                            fal fa-exclamation-triangle
                          </v-icon>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class='d-flex'>
                        <span>{{ item.comission }}</span>
                        <span class="mr-2" v-if="campaignPricing === 'cost_per_action_percent'"> % </span>
                        <span class="ml-2 mr-2" v-else> DKK </span>
                        <v-menu
                          v-model="item.menu"
                          :close-on-content-click="false"
                          :nudge-width="20"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <span>
                              <v-icon
                                size="12px"
                                class="greytxt--text cursor-pointer"
                                v-bind="attrs"
                                v-on="on"
                              >
                                fal fa-pencil
                              </v-icon>
                            </span>
                          </template>
                          <v-card>
                            <v-list>
                              <v-list-item>
                                <v-text-field
                                  label="Change commission"
                                  name="comission"
                                  type="number"
                                  v-model="item.comissionTemp"
                                  v-validate="{
                                   rules: { decimal: 2, required: true, min_value: 0 }
                                  }"
                                  :error-messages="errors.collect('comission')"
                                  class="mr-3"
                                  @change.native="isCommissionValid(item)"
                                ></v-text-field>
                                <div class="primary--text">
                                  <span v-if="campaignPricing === 'cost_per_action_percent'"> % </span>
                                  <span v-else> DKK </span>
                                </div>
                              </v-list-item>
                            </v-list>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn small color="priamry" text @click="closeItemMenu(item)">
                                Cancel
                              </v-btn>
                              <v-btn small color="success" @click="changeItemComission(item)" :disabled="!isCommissionValid(item)">
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                      </div>
                    </td>
                    <td>
                      <v-btn icon color="error" @click="removeInfluencer(item.uuid)">
                        <v-icon
                          size="18px"
                          class="error--text cursor-pointer"
                        >
                          fal fa-times
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
            <v-flex v-if="influencers.length === 0" class="d-flex justify-center my-4">
              Start searching for influencers 🚀
            </v-flex>
          </v-flex>
        </v-flex>
        <!-- mobile only -->
        <v-flex pt-3 v-if="$vuetify.breakpoint.mdAndDown">
          <simple-rich-text-input
            v-model="inviteNote"
            :height="145"
            :minlength="50"
            :maxlength="550"
            :textStrength="invitationNoteProgressIndicator"
            :customVariables="customVariables"
            :emojiOnly="true"
            :placeholder="editorPlaceholder"
            :label="{
              text: 'Invite message'
            }"
            :returnOnlyText="true"
            @valueCount="inviteNoteCount = $event"
          ></simple-rich-text-input>
        </v-flex>
        <v-card-actions v-if="$vuetify.breakpoint.mdAndDown">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">
            Close
          </v-btn>
          <v-btn
            color="success"
            :loading="loading"
            :disabled="isInviteNoteInvalid"
            @click="submit()"
          >
            Invite
          </v-btn>
        </v-card-actions>
      </v-flex>
    </v-card>
  </v-dialog>
</template>
<script>
import InfluencerAutocomplete from "@/components/autocompletes/InfluencerAutocomplete.vue";
import CampaignAutocomplete from "@/components/autocompletes/CampaignAutocomplete.vue";
import SimpleRichTextInput from "@/components/inputs/SimpleRichTextInput";
import UserAvatar from "@/components/avatars/UserAvatar";
import friendly_numbers from "@/helpers/filters/friendly_numbers";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "BulkInviteCampaignDialog",
  components: {
    InfluencerAutocomplete,
    CampaignAutocomplete,
    SimpleRichTextInput,
    UserAvatar
  },
  filters: {
    friendly_numbers
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedInfluencer: {
      type: Object,
      required: false
    },
    selectedCampaign: {
      type: String,
      required: false
    }
  },
  data: () => ({
    loading: false,
    influencersLoading: false,
    tempInfluencer: null,
    campaign: null,
    influencers: [],
    campaigns: [],
    inviteNote: "",
    inviteNoteCount: 0,
    validationExistingInvites: [],
    validationExistingInfluencers: [],
    influencerGroups: null,
    influencerHeaders: [
      { text: "Influencer", sortable: false },
      { text: "Followers", sortable: false },
      { text: "Commission", sortable: false },
    ],
    showCampaignSelect: false,
    editorPlaceholder: `  Ideas on what to write:
    Why does the influencer fit the campaign?
    Highlight relevant points from the campaign (Brand, product, etc.)`,
    customVariables: [
      {
        text: "First name",
        value: '{influencer_first_name}'
      },
      {
        text: "Last name",
        value: '{influencer_last_name}'
      },
      {
        text: "Full name",
        value: '{influencer_full_name}'
      },
      {
        text: "Campaign name",
        value: '{campaign_name}'
      },
      {
        text: "Brand name",
        value: '{business_name}'
      },
      {
        text: "Commission",
        value: '{commission}'
      }
    ]
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    ...mapGetters("core/auth", ["isBusiness"]),
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.clear();
        }
        this.$emit("input", value);
      }
    },
    countryCodes() {
      if (this.campaign && this.campaign.country) {
        return [this.campaign.country.iso_3166_2];
      } else {
        return [];
      }
    },
    canAddMoreInfluencers() {
      return this.user.business.remaining_allowed_influencers > 0;
    },
    campaignInvitedParticipants() {
      if (!this.campaign || !this.influencerGroups) {
        return [];
      } else {
        return [
          ...this.influencerGroups.accepted,
          ...this.influencerGroups.invited
        ];
      }
    },
    campaignPricing() {
      if (this.campaign) {
        return this.campaign.commission_type;
      } else {
        return null;
      }
    },
    excludeInfluencers() {
      const arr = [];
      if (this.influencers.length > 0) {
        for (let item of this.influencers) {
          arr.push(item.uuid);
        }
      }
      if (this.campaignInvitedParticipants.length > 0) {
        for (let item of this.campaignInvitedParticipants) {
          arr.push(item.influencer.uuid);
        }
      }
      return arr;
    },
    invitationNoteProgressIndicator() {
      return [
        {min: 0, max: 25, class: 'error--text'},
        {min: 50, max: 550, class: 'success--text'},
        {min: 550, max: 100000, class: 'error--text'}
      ];
    },
    includeRescricted() {
      let included = false;
      for (let item of this.influencers) {
        if (item.restricted) {
          included = true;
        }
      }
      return included;
    },
    isInviteNoteInvalid() {
      if (!this.campaign) {
        // "Select campaign first";
        return true;
      } else if (this.includeRescricted) {
        // "Some of users underage";
        return true;
      }

      for(let index in this.influencers) {
        let influencer = this.influencers[index];

        if(this.hasInfluencerAlreadyApplied(influencer.uuid)) {
          return true;
        }

        if(
          this.campaign.country &&
          this.campaign.country.iso_3166_2 &&
          influencer.country.iso_3166_2 !== this.campaign.country.iso_3166_2
        ) {
          return true;
        }
      }

      return this.influencers.length < 1 ||
              !this.inviteNote ||
              (
                this.inviteNoteCount < 25 ||
                this.inviteNoteCount > 550
              ) ||
              this.validationExistingInvites.length > 0;
    }
  },
  methods: {
    ...mapActions("core/campaigns", [
      "loadCampaigns",
      "bulkInviteCampaign",
      "getCampaignInfluencersGroups"
    ]),
    influencerCountryValidate(influencer) {
      if (
        this.campaign &&
        this.campaign.country &&
        this.campaign.country.iso_3166_2 &&
        influencer.country &&
        influencer.country.iso_3166_2
      ) {
        return influencer.country.iso_3166_2 !== this.campaign.country.iso_3166_2;
      } else {
        return false;
      }
    },
    isCommissionValid(item) {
      switch (this.campaignPricing) {
        case 'cost_per_click':
          if( item.comissionTemp >= 0 && item.max_commission_budget_tmp >= 100) {
            return true
          }
          break;
        case 'cost_per_action_percent':
        case 'cost_per_action_fixed':
          if( item.comissionTemp >= 0) {
            return true
          }
          break;
      }
      return false;
    },
    close() {
      this.showDialog = false;
    },
    submit() {
      this.loading = true;
      const influecersArr = this.influencers.map(item => {
        const obj = {
          influencer_uuid: item.uuid
        };

        obj[this.campaignPricing] = Number(item.comission);

        if(item.max_commission_budget) {
          obj['max_commission_budget'] = Number(item.max_commission_budget);
        }

        return obj;
      });
      const params = {
        campaign_uuid: this.campaign.uuid,
        influencers: influecersArr,
        note: this.inviteNote
      };
      this.bulkInviteCampaign(params).then(
        response => {
          this.$emit("success", response);
          this.loading = false;
          this.tempInfluencer = null;
          this.influencersLoading = true;
          this.influencers = [];
          this.getCampaigns(this.campaign.uuid);
          this.setSnackSuccess(`You have invited ${response.invitations.length} influencers`);
        },
        error => {
          this.loading = false;
          this.tempInfluencer = null;
          this.setSnackError(`An error happened`);
          console.log(error);
        }
      );
    },
    campaignAlredyInvitedValidation() {
      if (this.campaignInvitedParticipants.length === 0) {
        this.validationExistingInvites = [];
        this.validationExistingInfluencers = [];
      } else {
        let exist = [];
        for (let item of this.influencers) {
          const match = this.campaignInvitedParticipants.find(el => {
            return el.influencer.uuid === item.uuid;
          });
          if (match) {
            exist.push(item.uuid);
          }
        }
        this.validationExistingInvites = exist;
        this.validationExistingInfluencers = exist;
      }
    },
    hasInfluencerAlreadyApplied(influencerId) {
      if(! this.influencerGroups || ! this.influencerGroups.applied) {
        return;
      }

      return this.influencerGroups.applied.filter(campaignInfluencer =>
       influencerId === campaignInfluencer.influencer.uuid
      ).length > 0;
    },
    campaignCategoryInfluencerAgeValidation() {
      if (this.campaign) {
        const categoryId = this.campaign.category.id;
        this.influencers.map(item => {
          if (item.age_restrictions.includes(categoryId)) {
            item.restricted = true;
          } else {
            item.restricted = false;
          }
        });
      }
    },
    clear() {
      this.tempInfluencer = null;
      this.loading = false;
      this.$forceUpdate();
    },
    addInfluencer(influencer) {
      const included = this.influencers.find(item => {
        return item.uuid === influencer.uuid;
      });
      if (included) {
        return null;
      }
      if (this.campaign) {
        influencer.comission = this.campaign[this.campaignPricing];
        influencer.comissionTemp = this.campaign[this.campaignPricing];
        influencer.max_commission_budget = this.campaign.max_commission_budget;
        influencer.max_commission_budget_tmp = this.campaign.max_commission_budget;
      }
      this.influencers.push(influencer);
      this.campaignAlredyInvitedValidation();
      this.campaignCategoryInfluencerAgeValidation();
    },
    removeInfluencer(uuid) {
      this.influencers = this.influencers.filter(item => {
        return item.uuid !== uuid;
      });
      this.campaignAlredyInvitedValidation();
      this.campaignCategoryInfluencerAgeValidation();
    },
    editCampaign(value) {
      if (value) {
        this.campaign = null;
        this.campaignChange(false);
      }
      this.showCampaignSelect = value;
    },
    getInfluencersGroup(uuid) {
      this.influencersLoading = true;
      this.influencerGroups = null;
      const params = {
        campaign_uuid: uuid
      };
      this.getCampaignInfluencersGroups(params).then(
        data => {
          this.influencerGroups = data;
          this.campaignAlredyInvitedValidation();
          this.campaignCategoryInfluencerAgeValidation();
          this.influencersLoading = false;
        },
        error => {
          this.influencersLoading = false;
          this.setSnackError(`Failed to get campaign active influencers, try to select campaign again.`);
          console.log(error);
        }
      );
    },
    campaignChange(value) {
      if (value) {
        this.editCampaign(false);
        this.getInfluencersGroup(this.campaign.uuid);
        const comission = this.campaign[this.campaignPricing];
        this.influencers.map(item => {
          this.$set(item, "comission", comission);
          this.$set(item, "comissionTemp", comission);
          this.$set(item, "max_commission_budget", this.campaign.max_commission_budget);
          this.$set(item, "max_commission_budget_tmp", this.campaign.max_commission_budget);
        });
      } else {
        this.influencers.map(item => {
          this.$set(item, "comission", null);
          this.$set(item, "comissionTemp", null);
        });
        this.influencerGroups = null;
      }
    },
    getCampaigns(preselect_uuid) {
      const params = {
        page: 1,
        limit: 999,
        draft: false,
        active_campaigns: true
      };
      if (this.isBusiness) {
        params.business_uuid = this.user.business.uuid;
      }
      // Get all active campaigns
      this.loadCampaigns(params).then(
        campaigns => {
          this.campaigns = campaigns.response;
          if (preselect_uuid) {
            const item = this.campaigns.find(el => el.uuid === preselect_uuid);
            this.campaign = item;
          } else if (!preselect_uuid && this.campaigns.length === 1) {
            this.campaign = this.campaigns[0];
          }
        },
        () => {
          this.setSnackError("Error");
        }
      );
    },
    changeItemComission(item) {
      this.$set(item, "comission", item.comissionTemp);
      this.$set(item, "max_commission_budget", item.max_commission_budget_tmp);
      this.$set(item, "menu", false);
    },
    closeItemMenu(item) {
      this.errors.clear();
      this.$set(item, "comissionTemp", item.comission);
      this.$set(item, "menu", false);
    }
  },
  watch: {
    showDialog(val) {
      if (val) {
        if (this.campaign) {
          this.getCampaigns(this.campaign.uuid);
        } else {
          this.getCampaigns(this.selectedCampaign);
        }
        if (this.selectedInfluencer) {
          this.addInfluencer(this.selectedInfluencer);
        }
        if (!this.selectedCampaign && !this.campaign) {
          this.editCampaign(true);
        }
      }
    },
    campaign(newValue, oldValue) {
      // Break early if the same campaign
      if(newValue !== null && oldValue !== null && newValue.uuid === oldValue.uuid) {
        return;
      }

      this.campaignChange(newValue);
    },
    tempInfluencer(val) {
      if (val) {
        const exist = this.influencers.find(item => {
          return val.uuid === item.uuid;
        });
        if (!exist) {
          this.addInfluencer(val);
          this.tempInfluencer = null;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.influencers-table-wrapper {
  overflow: auto;
  max-height: 430px;
}
.custom-button {
  background: #EDF8F9;
}
</style>
